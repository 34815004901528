import { FC, useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { useAppSelector } from 'src/hooks'
import { ERoutes, IRouterOption, generate, routes } from 'src/router'
import { getGuide, getIsAuthenticated } from 'src/store/selectors/auth'
import { StorageUtils } from 'src/utils'
import { LayoutContainer } from '../layout-container'

const AuthRoute: FC<{
  path: string
  exact?: boolean
  component: IRouterOption['component']
}> = (props) => {
  const location = useLocation()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const mountedRef = useRef<boolean>(false)

  useEffect(() => {
    if (isAuthenticated) {
      mountedRef.current = true
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    return (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    )
  }

  const signInPath = generate(ERoutes.SIGN_IN)
  if (!mountedRef.current && location.pathname !== signInPath && location.pathname !== '/') {
    StorageUtils.setItem(AUTH_FALLBACK_KEY, `${location.pathname}${location.search}`)
  }

  return <Redirect to={signInPath}/>
}

const marketplaceRoutes = ['vibes', 'likes', 'intros', 'archive']

const RoutesSwitch = (props: { isDemo?: boolean }) => (
  <Switch>
    {Array.ensure(routes).map(item => {
      if (marketplaceRoutes?.find(marketplace => item.path.match(marketplace)) && !props.isDemo) {
        return <AuthRoute key={item.path} {...item} component={() => <Redirect to="/campaigns"/>}/>
      }

      if (item.meta?.requireAuth) {
        return <AuthRoute key={item.path} {...item}/>
      }

      return (
        <Route
          key={item.path}
          path={item.path}
          exact={item.exact}
          component={item.component}
        />
      )
    })}
  </Switch>
)

export const RouterView: FC = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const guide = useAppSelector(getGuide)

  return (
    <LayoutContainer>
      {isAuthenticated !== null && <RoutesSwitch isDemo={!!guide?.isDemo}/>}
    </LayoutContainer>
  )
}
