export const EEnv = Object.freeze({
  NODE_ENV: process.env.NODE_ENV as string,
  REACT_APP_ENABLE_SW: process.env.REACT_APP_ENABLE_SW as string,
  REACT_APP_FIREBASE_CONFIG: process.env.REACT_APP_FIREBASE_CONFIG as string,
  REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY as string,
  REACT_APP_GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS as string,
  REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY as string,
  REACT_APP_LOCATION_PROVIDER: process.env.REACT_APP_LOCATION_PROVIDER as string,
  REACT_APP_HIRING_API_DNS: process.env.REACT_APP_HIRING_API_DNS?.endsWith('/')
    ? process.env.REACT_APP_HIRING_API_DNS.slice(0, -1)
    : process.env.REACT_APP_HIRING_API_DNS,
  REACT_APP_LANDING_WEB_DNS: process.env.REACT_APP_LANDING_WEB_DNS?.endsWith('/')
    ? process.env.REACT_APP_LANDING_WEB_DNS.slice(0, -1)
    : process.env.REACT_APP_LANDING_WEB_DNS,
  REACT_APP_WEBSITE_WEB_DNS: process.env.REACT_APP_WEBSITE_WEB_DNS?.endsWith('/')
    ? process.env.REACT_APP_WEBSITE_WEB_DNS.slice(0, -1)
    : process.env.REACT_APP_WEBSITE_WEB_DNS,
  REACT_APP_CANDIDATE_WEB_DNS: process.env.REACT_APP_CANDIDATE_WEB_DNS?.endsWith('/')
    ? process.env.REACT_APP_CANDIDATE_WEB_DNS.slice(0, -1)
    : process.env.REACT_APP_CANDIDATE_WEB_DNS,
  REACT_APP_GITHUB_PUBLIC_IMAGE_DNS: process.env.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS?.endsWith('/')
    ? process.env.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS.slice(0, -1)
    : process.env.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS
})
