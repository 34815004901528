import axios from 'axios'
import { TAxiosResponseData } from 'src/interfaces'

export class PaymentApi {
  static readonly _prefix = '/payments'

  static get(): TAxiosResponseData<{ clientSecret: string }> {
    return axios.get(`${this._prefix}`)
  }

  static create(payload: {
    items: [
      {
        credits: number
        qty?: number
      }
    ]
  }): TAxiosResponseData<{ clientSecret: string }> {
    return axios.post(`${this._prefix}`, payload)
  }
}
