import clsx from 'clsx'
import { FC } from 'react'
import { CollapseContent } from 'src/components'

interface IProps {
  skills?: string
  characteristics?: string
  className?: string
}

export const CandidateSkillsNChars: FC<IProps> = ({ skills, characteristics, className }) => {
  return (
    <div className={clsx('fx fx-column', className)}>
      <CollapseContent borderTop className="px-6" title="Soft Skills">
        <pre className="body2-regular m-0 mt-2 pb-4 neutral-900">{characteristics || 'No content'}</pre>
      </CollapseContent>

      <CollapseContent borderTop className="px-6" title="Hard Skills">
        <pre className="body2-regular m-0 mt-2 pb-4 neutral-900">{skills || 'No content'}</pre>
      </CollapseContent>
    </div>
  )
}
