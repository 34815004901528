import axios from 'axios'
import { INotificationModel, INotificationSettingRecord, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class NotificationApi {
  static readonly _prefix = '/notifications'

  static pagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<INotificationModel> {
    return axios.get(`${this._prefix}`, { params })
  }

  static detail(
    id: number
  ): TAxiosResponseData<INotificationModel> {
    return axios.get(`${this._prefix}/${id}`)
  }

  static delete(
    id: number
  ): TAxiosResponseData<void> {
    return axios.delete(`${this._prefix}/${id}`)
  }

  static getSettings(): TAxiosResponseData<INotificationSettingRecord[]> {
    return axios.get(`${this._prefix}/settings`)
  }

  static updateSettings(
    data: INotificationSettingRecord[]
  ): TAxiosResponseData<void> {
    return axios.put(`${this._prefix}/settings`, {
      settings: data
    })
  }
}
