import { Box, IconButton } from '@mui/material'
import clsx from 'clsx'
import { FC, memo, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { TabletAndMobile, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconArrowLeft, IconBell, IconLogo } from 'src/icons'
import { Hamburger } from 'src/layout/layout-container/header/hamburger'
import { HeaderQuestionAnswer } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { PopupTourService } from 'src/services/tour/popup.service'
import Style from './style.module.scss'

interface ITitleActions {
  title: string
  handler: () => void
}

export const FakeHeader: FC<{
  showLogo?: boolean
  showBack?: boolean
  title?: string
  titleActions?: ITitleActions[]
  hideTutorial?: boolean
}> =
(memo(function Private({ showBack, title, titleActions, hideTutorial }) {
  const router = useHistory()
  const { eventHandler } = useAnalytic('')

  const subTitles = useMemo(() => title?.split('/'), [title])

  const goToSignUp = useCallback(() => {
    eventHandler(ETrackingEvent.BTN_NOTIFICATIONS)()
    router.push(generate(ERoutes.SIGN_UP))
  }, [router, eventHandler])

  const renderAction = useCallback((title: string) => {
    const findTitle = titleActions?.findIndex((item: ITitleActions) => item.title === title)

    return titleActions?.[findTitle || 0]?.handler()
  }, [titleActions])

  return (
    <>
      <div className={Style.header}>
        <Box display="flex" flexDirection="row" gap="16px" alignItems="center">
          <TabletAndMobile>
            <div className={Style.STLeftLogo}>
              <IconLogo width={48}/>
              <Hamburger/>
            </div>
          </TabletAndMobile>
          {showBack && (
            <IconButton className={Style.STIconButton} onClick={() => router.goBack()}>
              <IconArrowLeft/>
            </IconButton>
          )}
          {subTitles?.length && (
            <div className={Style.titleWrapper}>
              {subTitles?.map((subTitle, index) => (
                <span
                  key={index}
                  className={clsx(Style.subTitleWrapper, (index === subTitles.length - 1) && Style.lastSubTitleWrapper)}
                  onClick={() => renderAction(subTitle)}
                >
                  {subTitle}
                  {subTitles?.length !== index + 1 && ' / '}
                </span>
              ))}
            </div>
          )}
        </Box>
        <Box className={Style.STRight}>
          <HeaderQuestionAnswer hideTutorial={hideTutorial} onTutorialClicked={() => PopupTourService.show()}/>

          <IconButton className={Style.STIconButton} onClick={goToSignUp}>
            <IconBell color="#24252D"/>
          </IconButton>

        </Box>
      </div>
    </>
  )
}))
