import React, { FC } from 'react'
import Lottie from 'react-lottie'
import * as coinAnimationData from './coin.json'

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: coinAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface ICoinProps {
  // no here
  play?: boolean
  onCompleted?: () => void
}

export const Coin: FC<ICoinProps> = React.forwardRef(function RealCoin(
  props: ICoinProps,
  ref: any
) {
  return (
    <Lottie
      ref={ref}
      width={80}
      options={defaultOptions}
      isStopped={!props.play}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onCompleted?.()
        }
      ]}
    />
  )
})
