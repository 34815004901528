import Autocomplete from '@mui/material/Autocomplete'
import axios from 'axios'
import { debounce } from 'lodash'
import { ChangeEvent, FC, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Input } from '../input'
import { Spinner } from '../spinner'
import Style from './style.module.scss'

interface ILocation {
  place_id: number
  display_name: string
}

const inputSx = { marginBottom: 0 }

interface IProps {
  id?: string
  location?: string
  onChange?: (location: string) => void
  error?: string
}

export const LocationSearch: FC<IProps> = ({ id, location, onChange, error }) => {
  const [options, setOptions] = useState<readonly ILocation[]>([])
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({ display_name: location || '', place_id: 0 })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isInputRefFocused, setIsInputRefFocused] = useState(false)
  const [inputValue, setInputValue] = useState(location || '')

  useEffect(() => {
    setOpen(options.length > 0 && isInputRefFocused)
  }, [options, isInputRefFocused, setOpen])

  useEffect(() => {
    if (location) {
      setInputValue(location)
    }
  }, [location])

  useEffect(() => {
    if (location) {
      setSelectedLocation({ display_name: location, place_id: 0 })
    }
  }, [location])

  const handleBlur = useCallback(() => {
    setIsInputRefFocused(false)
  }, [setIsInputRefFocused])

  const handleFocus = useCallback(() => {
    setIsInputRefFocused(true)
  }, [setIsInputRefFocused])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchLocation = useCallback(
    debounce(async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          setOptions([])
          return
        }
        setLoading(true)
        const searchResults = await fetchLocationResults(searchTerm)
        setOptions(searchResults)
      } catch (error) {
        console.error('Error searching for locations:', error)
      } finally {
        setLoading(false)
      }
    }, 500),
    [setLoading, setOptions]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (!value) {
        setSelectedLocation({ display_name: '', place_id: 0 })
      }

      setInputValue(value)
      searchLocation(value)
      if (onChange) {
        onChange(value)
      }
    },
    [onChange, searchLocation, setInputValue]
  )

  const handleSelectLocation = useCallback(
    (event: SyntheticEvent, value: ILocation | null) => {
      if (value) {
        setSelectedLocation(value)
        if (onChange) {
          onChange(value.display_name)
        }
      }
    },
    [onChange]
  )

  return (
    <Autocomplete
      open={open}
      id={id}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      getOptionLabel={(option) => option.display_name}
      options={options}
      loading={loading}
      value={selectedLocation}
      onChange={handleSelectLocation}
      PaperComponent={({ children }) => <DropdownMenu>{children}</DropdownMenu>}
      renderInput={(params) => (
        <Input
          {...params}
          error={error}
          sx={inputSx}
          placeholder="Search"
          value={inputValue || ''}
          onChange={handleOnChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <Spinner sx={{ marginRight: '-40px' }} color="inherit" size={20}/> : null}
              </>
            )
          }}
        />
      )}
    />
  )
}

export const fetchLocationResults = async (searchTerm: string): Promise<readonly ILocation[]> => {
  try {
    const newAxios = await axios.create()
    const { data } = await newAxios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: searchTerm,
        format: 'json',
        countrycodes: 'us'
      }
    })

    return data.map((location: ILocation) => ({
      place_id: location.place_id,
      display_name: location.display_name
    }))
  } catch (error) {
    console.error('Error fetching location results:', error)
    return []
  }
}

const DropdownMenu: FC<{ children?: ReactNode }> = (props) => {
  return (
    <div className={Style.menuDropdown}>
      {props.children}
    </div>
  )
}
