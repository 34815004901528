import axios from 'axios'
import { ECommentReaction } from 'src/enums'
import { ICommentModel, ICommentSummaryModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class CommentApi {
  static readonly _prefix = '/comments'

  static pagination(
    params: IPaginationQuery & { email?: string; candidate?: string }
  ): TAxiosResponsePagination<ICommentModel> {
    return axios.get(`${this._prefix}`, { params })
  }

  static typing(
    payload: {
      candidateId: number
      commentableType?: string
      commentableId?: number
    }
  ): TAxiosResponseData<ICommentModel> {
    return axios.post(`${this._prefix}/typing`, payload)
  }

  static create(
    payload: {
      candidateId: number
      reaction?: ECommentReaction
      content: string
      commentableType?: string
      commentableId?: number
    }
  ): TAxiosResponseData<ICommentModel> {
    return axios.post(`${this._prefix}`, payload)
  }

  static createWithEmail(
    params: {email: string},
    payload: {
      candidateId: number
      content: string
      commentableType?: string
      commentableId?: number
    }
  ): TAxiosResponseData<ICommentModel> {
    return axios.post(`${this._prefix}`, payload, { params })
  }

  static detail(
    id: number
  ): TAxiosResponseData<ICommentModel> {
    return axios.get(`${this._prefix}/${id}`)
  }

  static summary(
    params: IPaginationQuery
  ): TAxiosResponseData<ICommentSummaryModel> {
    return axios.get(`${this._prefix}/summary`, { params })
  }

  static edit(
    id: number,
    payload: { content: string }
  ): TAxiosResponseData<void> {
    return axios.put(`${this._prefix}/${id}`, payload)
  }

  static delete(
    id: number
  ): TAxiosResponseData<void> {
    return axios.delete(`${this._prefix}/${id}`)
  }

  static checkCommented({ candidateId, email }: {
    candidateId: number
    email: string
  }): TAxiosResponseData<boolean> {
    return axios.post(`${this._prefix}/check-commented`, { candidateId, email })
  }
}
