export enum ECampaignRoutes {
  CAMPAIGNS = 'Campaigns',
  CAMPAIGN_SUBMISSION_DETAIL = 'Campaigns.SubmissionDetail',
  NEW_CAMPAIGN = 'Campaigns.NewCampaign',
  PREVIEW_CAMPAIGN = 'Campaigns.PreviewCampaign',
  CAMPAIGN_DETAIL = 'Campaigns.Detail',
  CAMPAIGN_MY_JOBS = 'Campaigns.MyJobs',

  CAMPAIGNS_PUBLIC = 'PublicCampaigns',
  CAMPAIGNS_PUBLIC_SUBMISSIONS = 'PublicCampaigns.Submissions'
}
