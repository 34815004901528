import { Box, ButtonBase, Divider, Fade, Typography, useTheme } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, Subject, catchError, from, takeUntil, tap, timer } from 'rxjs'
import { CampaignApi } from 'src/api'
import { Comment, NoTabletOrMobile, OnlyMobile, Share, TabletAndMobile, Tabs, Textarea, VideoPlayer, useAnalytic } from 'src/components'
import { EMessage, ESubmissionReaction, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useElementSize, useQueryParams, useUnsubscribeCallback, useUserOtherInfo } from 'src/hooks'
import { IconArrowDown, IconEmail, IconFilePdf, IconLikes, IconLikesCyan, IconLinkedInInfo, IconPhone, IconShareFilled } from 'src/icons'
import { ICampaignSubmissionModel, ITab } from 'src/interfaces'
import { CandidateInfoEducationSection, CandidateInfoExperiencesSection, CandidateSkillsNChars } from 'src/partials'
import { OverlayService, SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutShouldShowLoading } from 'src/store/actions'
import { getCredentials } from 'src/store/selectors'
import { getApiErrorMessage, getVideoSource, safeParseUrl, shortenFilename } from 'src/utils'
import Style from './style.module.scss'

const buttonBaseSx = { borderRadius: '40px' }
interface IProps {
  submissionId: number
  campaignId: number
}

export const SubmissionDetail: FC<IProps> = ({ campaignId, submissionId }) => {
  const theme = useTheme()
  const history = useHistory()
  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)
  const contentStyle = useMemo(() => ({ [(elWidth / elHeight) > (1076 / 826) ? 'height' : 'width']: '100%' }), [elHeight, elWidth])
  const credentials = useAppSelector(getCredentials)
  const { detailTab } = useQueryParams()
  const tab = useMemo(() => Number(detailTab) || 0, [detailTab])

  const dispatch = useAppDispatch()
  const { eventHandler, setScreen } = useAnalytic('')

  const [forceRender, setForceRender] = useState(0)
  const [data, setData] = useState<ICampaignSubmissionModel>()
  const [isLiked, setIsLiked] = useState<boolean>(false)

  const [note, setNote] = useState<string>('')
  const [isPrivateNotesSaved, setIsPrivateNotesSaved] = useState<boolean>(false)

  const linkedinWorkingExperiences = useMemo(() => data?.author.linkedinWorkingExperiences || [], [data])
  const linkedinEducations = useMemo(() => data?.author.linkedinEducations || [], [data])
  const otherInfo = useUserOtherInfo(data?.author)

  useEffect(() => {
    dispatch(setLayoutShouldShowLoading(true))
  }, [dispatch])

  const handleSetTab = (tabId: number) => {
    history.replace({
      search: `?detailSubmissionId=${submissionId}&detailTab=${tabId}`
    })
  }

  const tabs: ITab[] = [
    { title: 'Profile' },
    { title: 'Activity' }
  ]

  const handleOpenShare = useCallback(() => {
    if (data) {
      OverlayService.setOverlay({
        open: true,
        content: <Share videoId={data?.video?.id} candidateId={data.author.id} submissionId={data.id} setForceRender={setForceRender}/>
      })
    }
  }, [data])

  const handleChangeReaction = useUnsubscribeCallback((unsubscribe$) => {
    if (!data?.id || !data?.video?.id) {
      return
    }

    setIsLiked(prev => !prev)
    from(CampaignApi.action({
      campaignId: +campaignId,
      submissionId: data?.id,
      action: ESubmissionReaction.LIKE,
      videoId: data.video.id
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError(() => {
          setIsLiked(isLiked => !isLiked)
          return EMPTY
        })
      )
      .subscribe()
  }, [campaignId, data?.id, data?.video.id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSavePrivateNotes = useCallback(debounce((unsubscribe1$: Subject<void>, campaignId: number, submissionId: number, content: string) => {
    from(CampaignApi.writeNote(+campaignId, +submissionId, content))
      .pipe(
        takeUntil(unsubscribe1$),
        tap(() => {
          setIsPrivateNotesSaved(true)
          eventHandler({
            key: ETrackingEvent.INPUT_PRIVATE_NOTES,
            contextData: { candidateId: submissionId }
          })()
        }),
        catchError((error: AxiosError) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        })
      )
      .subscribe(() => {
        timer(3000).pipe(takeUntil(unsubscribe1$)).subscribe(() => {
          setIsPrivateNotesSaved(false)
        })
      })
  }, 300), [])

  const handlePrivateNotes = useUnsubscribeCallback(async (_unsubscribe$, e) => {
    if (!data) return

    setIsPrivateNotesSaved(false)
    const note = e.target.value || ''

    setNote(note)
    handleSavePrivateNotes(_unsubscribe$, +campaignId, +submissionId, note)
  }, [data, handleSavePrivateNotes])

  const loadDetailSubmission = useCallback(async () => {
    try {
      dispatch(setLayoutLoading(true))
      const { data } = await CampaignApi.getDetailSubmission(+campaignId, +submissionId)

      setData(data)
      setNote(data.privateNote?.content || '')
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }, [campaignId, dispatch, submissionId])

  useEffect(() => {
    if (data?.submissionReaction) {
      setIsLiked(data?.submissionReaction?.reaction === ESubmissionReaction.LIKE)
    }
  }, [data?.submissionReaction])

  useEffect(() => {
    if (campaignId && submissionId) {
      loadDetailSubmission()
    }
  }, [campaignId, dispatch, loadDetailSubmission, submissionId])

  useEffect(() => {
    if (!tab) {
      setScreen('campaign_submission_likes_profile')
    } else {
      setScreen('campaign_submission_share_comments')
    }
  }, [setScreen, tab])

  const Buttons = useMemo(() => (
    <Box className={Style.reactionAndShare}>
      <ButtonBase sx={buttonBaseSx} onClick={handleChangeReaction}>
        {isLiked
          ? (
            <div className={Style.STLike}>
              <Box><IconLikesCyan/></Box>
            </div>
          )
          : (
            <div className={Style.STUnLike}>
              <Box><IconLikes className={Style.STUnLikeIcon}/></Box>
            </div>
          )}
      </ButtonBase>

      {data?.campaign?.authorId === credentials.id && (
        <div className={Style.STButtonShare} onClick={handleOpenShare}>
          <IconShareFilled/>
          <Typography variant="body2-bold">Share</Typography>
        </div>
      )}

      {data?.video?.urlVideoSource && (
        <div
          className={Style.STButtonShare}
          onClick={() => window.open(data.video.urlVideoSource, '_blank', 'noreferrer')}
        >
          <IconArrowDown/>
          <Typography variant="body2-bold">Download</Typography>
        </div>
      )}
    </Box>
  ), [credentials.id, data, handleChangeReaction, handleOpenShare, isLiked])

  return (
    <StyledEngineProvider injectFirst>
      <div className={Style.STContainer} ref={containerRef}>
        {data?.author && (
          <div
            className={Style.submissionDetailContent}
            style={contentStyle}
          >
            <div className={clsx('p-4 fx fx-column gap-4', Style.videoContainer)}>
              <VideoPlayer
                id="video"
                className={Style.videoPlayer}
                videoId={data?.video?.id}
                tracks={data?.video?.tracks}
                trackingEvent
                image={data?.video?.urlVideoImageThumbnail}
                animatedImage={data?.video?.urlVideoAnimatedImage}
                url={getVideoSource(data?.video)}
                style={{ borderRadius: '16px' }}
                mimeType={data?.video?.internalSourceMetadata?.mimeType}
              />
              <TabletAndMobile>
                {Buttons}
              </TabletAndMobile>
            </div>

            <OnlyMobile>
              <div className={Style.mobileDivider}/>
            </OnlyMobile>

            <div className={clsx('fx fx-column', Style.infoContainer, { [Style.infoContainer_showingComment]: tab === 1 })}>
              <div className={clsx('fx fx-ai-center fx-jc-space-between px-6', Style.tabSpacing)}>
                <NoTabletOrMobile>
                  {Buttons}
                </NoTabletOrMobile>
                <Tabs tab={tab} tabs={tabs} setTab={handleSetTab}/>
              </div>

              <Divider/>

              {tab === 0 && (
                <div style={{ overflow: 'auto' }}>
                  <div className={Style.STSubmissionInfoContainer}>
                    <Box>
                      <h5 className={Style.FullNameTitle}>{data?.author.fullName}</h5>
                      {/* <p className={Style.LabelMethod}>Meet {data?.author.fullName} using these methods: </p> */}
                      <div className={Style.STPersonal}>
                        <Box>
                          <IconPhone/>
                          <Typography variant="body1-regular">{data?.author.userPhoneNo ? <a className={Style.ALink} href={`tel:+${data?.author.userPhoneNo}`}>{data?.author.userPhoneNo}</a> : 'Working On It'}</Typography>
                        </Box>
                        {data?.author.email && (
                          <Box>
                            <IconEmail width={20} height={20}/>
                            <Typography variant="body1-regular"><a className={Style.ALink} href={`mailto:${data.author.email}`}>{data.author.email}</a></Typography>
                          </Box>
                        )}
                        {data?.author.linkedInUrl && (
                          <Box>
                            <IconLinkedInInfo/><Typography variant="body1-regular"><a className={Style.ALink} target="_blank" href={data.author.linkedInUrl} rel="noreferrer">{data.author.linkedInUrl}</a></Typography>
                          </Box>
                        )}
                      </div>
                    </Box>
                  </div>

                  {(data.resumeFileName || data.portfolioUrl) && (
                    <>
                      <Divider/>
                      <div className={Style.STSubmissionInfoContainer}>
                        {data.resumeFileName && (
                          <div className={Style.STSubmissionInfoSection}>
                            <span className={Style.STSubmissionInfoLabel}>Resumé / portfolio</span>
                            <div
                              className={Style.STResumeFile}
                              onClick={() => data.resumeFileUrl && window.open(data.resumeFileUrl, '_blank', 'noreferrer')}
                            >
                              <IconFilePdf/>
                              <span>{(shortenFilename(data.resumeFileName) || 'No file uploaded')}</span>
                            </div>
                          </div>
                        )}

                        {data.portfolioUrl && (
                          <div className={Style.STSubmissionInfoSection}>
                            <span className={Style.STSubmissionInfoLabel}>Portfolio links </span>
                            <a className={Style.STPorfolioLink} target="_blank" href={safeParseUrl(data.portfolioUrl)} rel="noreferrer">{data.portfolioUrl}</a>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {(otherInfo.shouldShowOtherInfo) && (
                    <>
                      <Divider/>
                      <div className={Style.STSubmissionInfoContainer}>
                        <div className="fx flex-column gap-1">
                          <span className={Style.STSubmissionInfoLabel}>Others</span>
                          <div className={Style.STSubmissionInfoUL}>
                            {otherInfo.workInterestsText && (
                              <span className="body2-regular color-neutral-theme-400">{otherInfo.workInterestsText}</span>
                            )}

                            {otherInfo.willingToRelocateText && (
                              <span className="body2-regular color-neutral-theme-400">{otherInfo.willingToRelocateText}</span>)}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <Divider/>
                  <div className={Style.STSubmissionInfoContainer}>
                    <div className={Style.STNotes}>
                      <span className={Style.STSubmissionInfoLabel}>Private Notes</span>
                      <Textarea
                        className={Style.STPrivateNotes}
                        label=""
                        placeholder="Only you can see your private notes"
                        value={note}
                        onChange={handlePrivateNotes}
                        autoComplete="off"
                        disableResize
                        minRows={6}
                        maxRows={6}
                      />
                      <Fade in={isPrivateNotesSaved}>
                        <Typography mt="4px" variant="body2" color={theme.colors['--color-neutral-theme-300']}>Note saved</Typography>
                      </Fade>
                    </div>
                  </div>

                  {linkedinWorkingExperiences.length > 0 && (
                    <CandidateInfoExperiencesSection linkedinWorkingExperiences={linkedinWorkingExperiences}/>
                  )}

                  {linkedinEducations.length > 0 && (
                    <CandidateInfoEducationSection linkedinEducations={linkedinEducations}/>
                  )}

                  <CandidateSkillsNChars
                    skills={data?.author?.skills}
                    characteristics={data?.author?.characteristics}
                  />
                </div>
              )}
              {tab === 1 && (
                <Comment
                  className="fx-1"
                  hideInvite={data?.campaign?.authorId !== credentials.id}
                  showCommentInput={data?.campaign?.authorId !== credentials.id}
                  forceRender={forceRender}
                  candidateId={data.author.id}
                  videoId={data.video.id}
                  submissionId={data.id}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </StyledEngineProvider>
  )
}
