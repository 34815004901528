import { FC } from 'react'
import Lottie from 'react-lottie'
import * as gifOpenAnimationData from './giftopen.json'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: gifOpenAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface IGiftOpenProps {
  onComplete?: () => void
}

export const GiftOpen: FC<IGiftOpenProps> = (props) => {
  return (
    <Lottie
      options={defaultOptions}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onComplete?.()
        }
      ]}
    />
  )
}
