import axios from 'axios'
import { ICandidateModel, TAxiosResponseData } from 'src/interfaces'

export class ViewApi {
  static readonly _prefix = '/view'

  static share(
    sharingToken: string
  ): TAxiosResponseData<ICandidateModel> {
    return axios.get(`${this._prefix}/${sharingToken}`)
  }
}
