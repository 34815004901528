import axios from 'axios'
import { TAxiosResponseData } from 'src/interfaces'

export class PromoCodeApi {
  static readonly _prefix = '/promo-code'

  static apply(code: string): TAxiosResponseData<{ introCredit: number; credit: number }> {
    return axios.post(this._prefix, { code })
  }
}
