import clsx from 'clsx'
import { FC, useCallback, useRef, useState } from 'react'
import { Swiper } from 'swiper/react'

import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { Button } from 'src/components'
import { EEnv } from 'src/constants'
import { IconChevronLeft48 } from 'src/icons'
import { OverlayService } from 'src/services'
import { StorageUtils } from 'src/utils'
import 'swiper/css/effect-flip'
import { OnboardingSlider } from '../slider'
import { SwiperButtonLastSlide } from '../swipe-button-last-slide'
import { SwiperNavButton } from '../swipe-nav-button'
import Style from './style.module.scss'

enum ENavAction {
  PREV = 'prev',
  NEXT = 'next'
}

interface IMyJobGuessOnboardingProps {
  hashId: string
}

export const MyJobGuessOnboarding: FC<IMyJobGuessOnboardingProps> = ({ hashId }) => {
  const swiperBtnPrevRef = useRef<any>(null)
  const swiperBtnNextRef = useRef<any>(null)
  const swiperBtnLastRef = useRef<any>(null)

  const [currSlideIndex, setCurrSlideIndex] = useState<number>(0)

  const handleChangeSlideIndex = useCallback((index: number) => {
    setCurrSlideIndex(index)
  }, [])

  const handleEndOnboarding = useCallback(() => {
    StorageUtils.setItem(`guess.${hashId}.onboarding`, true)
    return OverlayService.reset()
  }, [hashId])

  return (
    <div>
      <Typography variant="h3" color="white" className="mb-6 text-center">
        How to Get Started?
      </Typography>
      <div className={Style.iconBottom}>
        <div className={clsx('fx gap-3', Style.dots)}>
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className={clsx(Style.dot, {
                  [Style.active]: index === currSlideIndex
                })}
              />
            ))}
        </div>
      </div>
      <div className="fx fx-ai-center gap-6 relative">
        <button
          className={clsx(
            'fx fx-ai-center fx-jc-center',
            Style.navBtn,
            Style.prev,
            {
              [Style.disabled]: currSlideIndex === 0
            }
          )}
          onClick={() => swiperBtnPrevRef.current.click()}
        >
          <IconChevronLeft48 className="neutral-900"/>
        </button>
        <div className={clsx(Style.slides)}>
          <Swiper
            grabCursor
            slidesPerView={1}
            onRealIndexChange={(swiper) => {
              handleChangeSlideIndex(swiper.activeIndex)
            }}
          >
            <SwiperNavButton
              className="d-none"
              type={ENavAction.PREV}
              ref={swiperBtnPrevRef}
            />
            <OnboardingSlider
              videoSrcs={[
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/01.mp4`,
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/02.mp4`
              ]}
              titleElement={(
                <div className={Style.onboardingTitleWrapper}>
                  <h5 className={clsx('m-0 color-white text-center', Style.onboardingTitle)}>
                    <span className="color-primary-300">KNOWME</span> is a modern way to catch a glimpse of who is <span className="color-primary-600">actually</span> behind a resume
                  </h5>
                </div>
              )}
              onClose={handleEndOnboarding}
              show={currSlideIndex === 0}
            />

            <OnboardingSlider
              videoSrcs={[
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/03.mp4`,
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/04.mp4`
              ]}
              titleElement={(
                <div className={Style.onboardingTitleWrapper}>
                  <h5 className={clsx('m-0 color-white text-center', Style.onboardingTitle)}>
                    <span className="color-primary-600">Stand Out!</span> You are a primary reason why someone should work for your company!
                  </h5>
                </div>
              )}
              onClose={handleEndOnboarding}
              show={currSlideIndex === 1}
            />

            <OnboardingSlider
              videoSrcs={[
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/05.mp4`,
                `${EEnv.REACT_APP_GITHUB_PUBLIC_IMAGE_DNS}/videos/06.mov`
              ]}
              titleElement={(
                <div className={Style.onboardingTitleWrapper}>
                  <h5 className={clsx('m-0 color-white text-center', Style.onboardingTitle)}>
                    Create a job with <span className="color-primary-300">KNOWME</span> and <span className="color-primary-600">share everywhere</span>
                  </h5>
                </div>
              )}
              text="(Company website, LinkedIn and other jobs marketplaces)"
              onClose={handleEndOnboarding}
              show={currSlideIndex === 2}
            />
            <SwiperNavButton
              className="d-none"
              type={ENavAction.NEXT}
              ref={swiperBtnNextRef}
            />
            <SwiperButtonLastSlide
              lastIndex={2}
              ref={swiperBtnLastRef}
              className="d-none"
            />
          </Swiper>
        </div>
        <div className={Style.navigation}>
          {currSlideIndex === 2
            ? (
              <motion.div layout layoutId="1">
                <Button className={Style.startBtn} onClick={handleEndOnboarding}>
                  Let’s Go!
                </Button>
              </motion.div>
            )
            : (
              <button
                className={clsx(
                  'fx fx-ai-center fx-jc-center',
                  Style.navBtn,
                  Style.next,
                  {
                    [Style.disabled]: currSlideIndex === 2
                  }
                )}
                onClick={() => swiperBtnNextRef.current.click()}
              >
                <IconChevronLeft48 className="neutral-900"/>
              </button>
            )}

        </div>
      </div>
      {currSlideIndex !== 2 && (
        <motion.div layout layoutId="1" className={Style.bottomBtn}>
          <Button
            order="secondary"
            height={40}
            className={clsx(Style.skipBtn, { [Style.isHidden]: currSlideIndex === 2 })}
            onClick={handleEndOnboarding}
          >Skip
          </Button>
        </motion.div>
      )}
    </div>
  )
}
