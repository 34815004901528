import { Typography, useTheme } from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components/button'
import { Spinner } from 'src/components/spinner'
import { VideoPlayer } from 'src/components/video-player'
import { CREDIT_FOR_FIRST_TIME_VVC } from 'src/constants'
import { EMessage } from 'src/enums'
import { useBehaviorMapper, useImageFromFile } from 'src/hooks'
import { IconCameraPlus, IconConfetti, IconUpload, IconVideo } from 'src/icons'
import { IOverlayProps } from 'src/interfaces/overlay-props.interface'
import { ERoutes, generate } from 'src/router'
import { ERecordFor, OverlayService, ProfileService, SnackbarService } from 'src/services'
import { PopupTourService } from 'src/services/tour/popup.service'
import { allowedAudioTypes } from 'src/utils'
import Style from './style.module.scss'

export const VVCPopup: FC & IOverlayProps = () => {
  const theme = useTheme()
  const router = useHistory()
  const pfvFiles = useBehaviorMapper(PopupTourService.pfvFiles)

  const recordResultRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const bgAudioSrc = useImageFromFile(pfvFiles?.placeholder?.file || pfvFiles?.placeholder?.src || undefined)

  const handleSubmitPfv = useCallback(() => {
    if (!pfvFiles?.file) {
      return
    }

    setLoading(true)

    ProfileService
      .uploadPfv(pfvFiles.file, pfvFiles.placeholder, undefined, pfvFiles.thumbnailOffset)
      .then(() => {
        OverlayService.reset()
        PopupTourService.startFlyingCoin()
      })
      .catch(err => {
        console.error('failed to upload vvc from vvc-popup', err)
        SnackbarService.push({ content: 'Failed to upload vvc, please try later', severity: EMessage.ERROR })
      })
  }, [pfvFiles])

  const handleFileSelected = (record: File) => {
    PopupTourService.setPfvFile({
      file: record
    })
  }

  const handleRecord = () => {
    OverlayService.reset()
    PopupTourService.hide()
    router.push(generate(ERoutes.RECORDING, { type: ERecordFor.PFV_TOUR_SERVICE }))
  }

  return (
    <div className={Style.container}>
      <div className="d-none">
        <input
          type="file"
          ref={recordResultRef}
          accept={['video/*', ...allowedAudioTypes].join(', ')}
          onChange={(e) => {
            if (!e.target.files?.[0]) return
            handleFileSelected(e.target.files?.[0])
            e.target.value = ''
          }}
        />
      </div>

      <div className={Style.top}>
        <div className={Style.left}>
          <IconConfetti width="61px" height="61px"/>

          <div className={Style.leftText}>
            {pfvFiles?.file
              ? (
                <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} align="center">
                  You’ve Made it
                </Typography>
              )
              : (
                <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} align="center">
                  Be the first 100 to create a video vibe check and get <span className="color-cyan-500">{CREDIT_FOR_FIRST_TIME_VVC} free</span> credits
                </Typography>
              )}
            <span className="body2-medium color-neutral-theme-300 text-center">
              With 1 credit, you can unlock 1 candidate.
              By earning more credits, you can unlock many of your favorites candidates.
            </span>
          </div>
        </div>
        <div className={Style.right}>
          {!pfvFiles?.file
            ? (
              <div className={Style.rightEmpty}>
                {loading && (
                  <div className={Style.loadingContainer}>
                    <Spinner/>
                  </div>
                )}
                <IconVideo width={60} height={60}/>
                <div className="fx flex-column fx-ai-center gap-2 w-100">
                  <Button
                    fullWidth
                    kmSize="medium"
                    startIcon={<IconCameraPlus width={18} height={18}/>}
                    onClick={handleRecord}
                  >Record Now
                  </Button>
                  <Button
                    fullWidth
                    kmSize="medium"
                    order="secondary"
                    startIcon={<IconUpload width={17} height={17}/>}
                    onClick={() => recordResultRef.current?.click?.()}
                  >Upload
                  </Button>
                </div>
              </div>
            )
            : (
              <div className={Style.rightWithVideo}>
                {loading && (
                  <div className={Style.loadingContainer}>
                    <Spinner/>
                  </div>
                )}
                <VideoPlayer
                  forceRender
                  className={Style.video}
                  url={pfvFiles?.file}
                  hideStartEnd
                  image={bgAudioSrc || undefined}
                />
                <div className={Style.videoButtons}>
                  <Button
                    fullWidth
                    kmSize="medium"
                    startIcon={<IconCameraPlus width={18} height={18}/>}
                    order="black"
                    onClick={handleRecord}
                  >Record Again
                  </Button>
                  <Button
                    fullWidth
                    kmSize="medium"
                    order="secondary"
                    startIcon={<IconUpload width={17} height={17}/>}
                    onClick={() => recordResultRef.current?.click?.()}
                  >Upload
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>

      {pfvFiles?.file && (
        <div className={Style.bottom}>
          <Button
            fullWidth
            onClick={handleSubmitPfv}
            disabled={loading}
          >Submit
          </Button>
        </div>
      )}
    </div>
  )
}

VVCPopup.getOverlayState = () => ({
  open: true,
  content: <VVCPopup/>,
  onClose: () => {
    PopupTourService.hideVVCPopup()
    if (!PopupTourService.isShowingPopup$.getValue()) {
      PopupTourService.show()
    }
  }
})
