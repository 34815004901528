import { Box, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi } from 'src/api'
import { AlertMessage, Button, Input } from 'src/components'
import { PRIVACY_POLICY_URL, SST_GUEST_CAMPAIGN_ID, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useOldValidation, useQueryParams, useUnsubscribe } from 'src/hooks'
import { IconInfo } from 'src/icons'
import { Footer } from 'src/layout/layout-container/footer'
import { Header } from 'src/modules/auth/components/header'
import { LeftSection } from 'src/modules/auth/components/left-section'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
import { homeSchema } from 'src/validation'
import Style from './style.module.scss'

export const HintContent = <><IconInfo className={Style.iconInfo}/>Your email must be a work email</>

interface IProps {
  onChangeView?: () => void
  formClassName?: string
}

export const RegisterEmail: FC<IProps> = ({ onChangeView, formClassName }) => {
  const unsubscribe$ = useUnsubscribe()
  const history = useHistory()
  const { inviteToken } = useQueryParams()
  const theme = useTheme()

  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { errors, validateAt, validate } = useOldValidation<{ email?: string }>()
  const sstCampaignHashId = useMemo(() => sessionStorage.getItem(SST_GUEST_CAMPAIGN_ID) || undefined, [])

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: homeSchema,
      path: name,
      data: { [name]: value }
    })

    setError('')
    setEmail(value)
  }

  const [loading, setLoading] = useState(false)
  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    const isValid = await validate({ schema: homeSchema, data: { email } })
    if (!isValid) {
      return
    }

    const promise = AuthApi.signUpRequest({
      email,
      inviteToken: inviteToken as string,
      campaignHashId: sstCampaignHashId,
      sessionId: AnalyticApi._session
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          setError(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
        })
      )
      .subscribe(() => {
        onChangeView?.()
        const search = inviteToken ? `?inviteToken=${inviteToken}` : ''
        history.replace({
          pathname: 'sign-up',
          search,
          state: email
        })
      })
  }

  const goToLogin = useCallback(() => {
    OverlayService.reset()
    history.push(generate(ERoutes.SIGN_IN))
  }, [history])

  return (

    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>
        <LeftSection/>
        <div className={Style.RightSide}>
          <div className={Style.STContainer}>
            <form className={clsx(Style.STForm, formClassName)} onSubmit={handleSubmit}>
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Sign up</Typography>
                <Typography align="center" className={Style.Description}>
                  We are the fastest growing video first marketplace for remote jobs.
                </Typography>
              </div>

              {error && (
                <AlertMessage
                  className={Style.ErrorMsg}
                  message={error}
                  mb={4}
                  icon={<IconInfo/>}
                />
              )}

              <div className={Style.FormInput}>
                <Input
                  label="Email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="name@company.com"
                  disabled={loading}
                  value={email || ''}
                  onChange={handleChangeInput}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                />

                {errors.email && (
                  <Typography
                    className={clsx(Style.STSignUpEmailHint, {
                      [Style.STSignUpEmailHintNotError]: !errors.email,
                      [Style.STSignUpEmailHintErrorWithEmail]: errors.email && email,
                      [Style.STSignUpEmailHintDefault]: !errors.email && !email
                    })}
                  >
                    {HintContent}
                  </Typography>
                )}

                <Button
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  Sign Up
                </Button>

                <Box className={Style.GuideSection}>
                  <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-300']}>
                    By creating an account, you accept KnowMe’s
                  </Typography>
                  <span
                    className={Style.Link}
                    onClick={() => window.open(TERMS_AND_SERVICE_URL, '_blank')}
                  > Terms of Use {' '}
                  </span>
                  <Typography variant="body1-regular" color={theme.colors['--color-neutral-theme-300']}>
                    and acknowledge our
                  </Typography>
                  <span
                    className={Style.Link}
                    onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
                  > Privacy Policy
                  </span>
                  .
                </Box>
                <Box className={Style.Box}>
                  <Typography
                    variant="body1-regular"
                    color={theme.colors['--color-neutral-theme-900']}
                  >You already have an account?
                  </Typography>
                  <Typography
                    component="a"
                    color={theme.colors['--color-cyan-600']}
                    onClick={goToLogin}
                    sx={{ cursor: 'pointer' }}
                  >
                    Login
                  </Typography>
                </Box>
              </div>
            </form>
          </div>
          <Footer/>
        </div>
      </div>
    </>
  )
}
