import axios from 'axios'
import { IUserModel, TAxiosResponseData } from 'src/interfaces'

export class AuthApi {
  static readonly _prefix = '/auth'

  static login(payload: Pick<IUserModel, 'email' | 'password'>): TAxiosResponseData<{
    id: number
    token: string
    tokenType: 'Bearer'
    tokenExpireAt: string
    tokenExpireIn: number
    profile: IUserModel
  }> {
    return axios.post(`${this._prefix}/sign-in`, payload)
  }

  static logout(): TAxiosResponseData<void> {
    return axios.delete(`${this._prefix}/sign-out`)
  }

  /**
   * SECTION: Sign up
   */

  static signUpQuick(payload: { sharingToken: string; password: string }): TAxiosResponseData<void> {
    return axios.post(`${this._prefix}/quick-sign-up`, payload)
  }

  static signUpVerify(params: { token: string }): TAxiosResponseData<{ email: string; inviteToken?: string; campaignHashId?: string }> {
    return axios.get(`${this._prefix}/sign-up`, { params })
  }

  static signUpRequest(payload: { email: string; inviteToken?: string; campaignHashId?: string; sessionId?: string }): TAxiosResponseData<{ token: string }> {
    return axios.post(`${this._prefix}/sign-up`, payload, {
      headers: {
        authorization: ''
      }
    })
  }

  static signUpProfile(
    payload: {
      password: string
      interested?: string
      subscribeMarketingEmails?: boolean
      campaignHashId?: string
      name: string
    },
    params: { token: string }
  ): ReturnType<typeof AuthApi.login> {
    return axios.put(`${this._prefix}/sign-up`, payload, {
      params,
      headers: {
        authorization: ''
      }
    })
  }

  /**
   * Complete profile
   */
  static complete(payload: {
    firstName: string
    lastName: string
    companyName: string
    companyUrls: string[]
    subscribeMarketingEmails: boolean
  }): TAxiosResponseData<void> {
    return axios.post(`${this._prefix}/complete-profile`, payload)
  }
}
