import { ComponentProps, FC, useEffect, useState } from 'react'
import { from, takeUntil } from 'rxjs'
import { Button, Loading, VideoRecorder } from 'src/components'
import { useUnsubscribe } from 'src/hooks'
import { CameraService } from 'src/services'
import { Countdown } from './countdown'
import { StartRecordBtn } from './start-record-btn'
import { StopRecordBtn } from './stop-record-btn'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof VideoRecorder>, 'onStopReplaying' | 'onStop' | 'onStartOver'> {
  className?: string
  onStopReplaying?: (...args: any[]) => any
  onStop: (videoBlob: Blob) => any
  onStartOver: () => any
  onSubmit: () => any
}

export const RecorderVideo: FC<IProps> = ({ className, onSubmit, onStartOver, ...props }) => {
  const unsubscribe$ = useUnsubscribe()

  /**
   * TODO: implement loading
   */
  const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraintSet>()

  useEffect(() => {
    const promise = CameraService.getBestResolution({
      width: 9,
      height: 16
    })

    from(promise)
      .pipe(takeUntil(unsubscribe$))
      .subscribe(setVideoConstraints)

    return () => {
      CameraService.stop()
    }
  }, [unsubscribe$])

  return (
    <div className={Style.recorderVideoContainer}>
      {videoConstraints
        ? (
          <VideoRecorder
            isOnInitially
            showReplayControls
            {...props}
            isFlipped
            constraints={{
              audio: true,
              video: videoConstraints
            }}
            onRecordingComplete={(videoBlob: Blob) => {
              props.onRecordingComplete?.(videoBlob)
              props.onStop?.(videoBlob)
            }}
            renderLoadingView={() => <span/>}
            renderActions={(props: any) => {
              return (
                <>
                  {props.isRunningCountdown && (
                    <Countdown time={props.countdownTime}/>
                  )}

                  <div className={Style.VideoActions}>
                    {props.isRecording
                      ? <StopRecordBtn onClick={props.onStopRecording}/>
                      : props.isCameraOn && props.streamIsReady && !props.isRunningCountdown
                        ? <StartRecordBtn onClick={props.onStartRecording}/>
                        : null}

                    {props.isReplayingVideo && (
                      <div className={Style.ReplayingActions}>
                        <Button
                          id="recording-submit-btn"
                          className={Style.SubmitBtn}
                          onClick={onSubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          id="recording-start-over-btn"
                          className={Style.StartOverBtn}
                          order="tertiary"
                          onClick={onStartOver}
                        >
                          Start Over
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )
            }}
          />
        )
        : <Loading/>}
    </div>
  )
}
