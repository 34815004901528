import { ClickAwayListener, Divider, InputAdornment, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { takeUntil } from 'rxjs'
import { GuideApi, PromoCodeApi } from 'src/api'
import { Button, NoTabletOrMobile, Upgrade, useAnalytic } from 'src/components'
import { ETrackingEvent, EUserGuide } from 'src/enums'
import { useAppDispatch, useAppSelector, useIfMobileL, useUnsubscribe } from 'src/hooks'
import { IconCredit } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import { CreditService, NavigationService, NotificationService, OverlayService } from 'src/services'
import { setFirstTimeClickCredit } from 'src/store/actions/auth'
import { setCountCredit } from 'src/store/actions/profile'
import { getGuide, getProfileCountCredits } from 'src/store/selectors'
import { TARGET_WALLET, getApiErrorMessage } from 'src/utils'
import { ClaimCredit } from './components/claim-animation'
import { CreditInstruction } from './components/credit-instruction'
import {
  STContainer,
  STCredit,
  STDropdown,
  STDropdownItem,
  STPromoButton,
  STPromoInput,
  STPromoInputError,
  STPromoLabel
} from './styled'

import Style from './style.module.scss'

export const Credit: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const dispatch = useAppDispatch()
  const countCredits = useAppSelector(getProfileCountCredits)
  const guide = useAppSelector(getGuide)
  const { eventHandler } = useAnalytic('')
  const isMobile = useIfMobileL()

  const [isOpen, setIsOpen] = useState(false)
  const [promoCode, setPromoCode] = useState<string>('')
  const [isPromoCodeError, setIsPromoCodeError] = useState<string>('')
  const [isApplyingPromo, setIsApplyingPromo] = useState(false)
  const [playClaimCredit, setPlayClaimCredit] = useState(false)
  const [currCreditPoints, setCurrCreditPoints] = useState(0)

  const text = useMemo(() => {
    return countCredits > 1 ? ' Credits' : ' Credit'
  }, [countCredits])

  const handleBuyMoreUnit = () => {
    if (isMobile) {
      CreditService.setShowBuyMoreCreditsMobileModal(true)
      return
    }

    OverlayService.setOverlay({
      open: true,
      content: <Upgrade/>
    })
  }

  const handleChangePromoCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value)
    setIsPromoCodeError('')
  }, [setPromoCode, setIsPromoCodeError])

  useEffect(() => {
    NotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        const fetchedCreditPoints = data?.countCredits ?? 0
        dispatch(setCountCredit(fetchedCreditPoints))
      })
  }, [countCredits, dispatch, playClaimCredit, unsubscribe$])

  useEffect(() => {
    setCurrCreditPoints(countCredits)

    if (countCredits === currCreditPoints - 1) {
      setPlayClaimCredit(true)
    }
  }, [countCredits, currCreditPoints])

  const applyPromo = useCallback(async () => {
    try {
      setIsApplyingPromo(true)
      const { data } = await PromoCodeApi.apply(promoCode)

      NavigationService.setShowRedeemCode(true, data.introCredit)
      setIsOpen(false)
    } catch (error) {
      setIsPromoCodeError(getApiErrorMessage(error) || 'Promo code is invalid or expired.')
    } finally {
      setIsApplyingPromo(false)
    }
  }, [setIsOpen, promoCode])

  const handleFirstClickCredit = useCallback(() => {
    GuideApi.edit(EUserGuide.FIRST_TIME_CLICK_CREDIT)
    dispatch(setFirstTimeClickCredit({ firstTimeClickCredit: true }))
    setIsOpen(!isOpen)
  }, [dispatch, isOpen])

  const handleClickCredit = useCallback(() => {
    if (!guide?.firstTimeClickCredit) {
      OverlayService.setOverlay({
        open: true,
        content: <CreditInstruction/>,
        onClose: handleFirstClickCredit,
        closeStyle: { top: '-16px', right: '-24px' },
        overlayBackground: 'rgba(0 0 0 / 80%)',
        isHiddenBackdropFilter: true
      })
    } else {
      setIsOpen(!isOpen)
    }
  }, [guide?.firstTimeClickCredit, handleFirstClickCredit, isOpen])

  return (
    <STContainer>
      <STCredit onClick={handleClickCredit} id={TARGET_WALLET.getValue()}>
        <IconCredit/>
        <Typography variant="body2-medium" color={theme.colors['--color-neutral-theme-700']}>
          {countCredits}
        </Typography>
        <NoTabletOrMobile>
          <Typography variant="body2-medium" color={theme.colors['--color-neutral-theme-700']}>
            {text}
          </Typography>
        </NoTabletOrMobile>
        {playClaimCredit && (
          <div className={clsx('absolute t-1 l-0', Style.claimCreditAnimation)}>
            <ClaimCredit play={playClaimCredit} onCompleted={() => setPlayClaimCredit(false)}/>
          </div>
        )}
      </STCredit>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(!isOpen)}>
          <STDropdown>
            <Button fullWidth height={56} onClick={eventHandler(ETrackingEvent.BTN_STORE, handleBuyMoreUnit)}>Buy More Credits</Button>
            <Divider/>
            <STDropdownItem>
              <STPromoLabel>Enter Promo Code</STPromoLabel>
              <STPromoInput
                value={promoCode}
                disabled={isApplyingPromo}
                onChange={handleChangePromoCode}
                placeholder="Ex: 348HDF"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <STPromoButton disabled={isApplyingPromo || !promoCode} onClick={applyPromo} order="secondary">Apply</STPromoButton>
                    </InputAdornment>
                  )
                }}
              />
              {isPromoCodeError && (<STPromoInputError>{isPromoCodeError}</STPromoInputError>)}
            </STDropdownItem>
            <Divider/>
            <STDropdownItem onClick={eventHandler(ETrackingEvent.BTN_OPTIONS_PAYMENTS, () => history.push(generate(ERoutes.PAYMENT_HISTORY)))}>
              <Typography variant="body2-regular">Payment History</Typography>
            </STDropdownItem>
          </STDropdown>
        </ClickAwayListener>
      )}
    </STContainer>
  )
}
