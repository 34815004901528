import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { shuffle, uniqWith } from 'lodash'
import { FC, SVGProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi, GuideApi } from 'src/api'
import { EEmptyVideoVariant, IconWrapper, Tooltip, Traits, VideoPlayer, useAnalytic } from 'src/components'
import { ECandidateReaction, EMessage, EReactionTable, ETrackingEvent, EUserGuide } from 'src/enums'
import { useAppDispatch, useAppSelector, useDebounce, useElementSize, useUnsubscribe } from 'src/hooks'
import { IconArchive, IconLikes, IconNope, IconSuperLike } from 'src/icons'
import { ICandidateModel, IPaginationResponse } from 'src/interfaces'
import { DialogVerifyProfile } from 'src/partials'
import { BannerService, NavigationService, OverlayService, SnackbarService } from 'src/services'
import { setLayoutAside, setLayoutLoading, setLayoutPageComponent, setLayoutPageTitle, setLayoutShouldShowLoading } from 'src/store/actions'
import { setOnboarding } from 'src/store/actions/auth'
import {
  getAuthStat,
  getCredentials,
  getGuide,
  getLayoutIsLoading,
  getProfileTraits
} from 'src/store/selectors'
import { ensureArray, getApiErrorMessage, getVideoSource } from 'src/utils'
import { EmptyVibes } from '../empty-vibes'
import Style from './style.module.scss'

const videoPlayerSx = {
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  aspectRatio: '445 / 787',
  height: 'unset',
  backgroundColor: 'black'
}

interface IIcon {
  title: string
  event: ETrackingEvent
  action: ECandidateReaction
  icon: FC<SVGProps<SVGSVGElement>>
  color?: string
  active?: {
    color?: string
    background?: string
  }
}

export const Vibes: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const location = useLocation<{from: string}>()
  const history = useHistory()
  const traits = useAppSelector(getProfileTraits)
  const profile = useAppSelector(getCredentials)
  const isLoading = useAppSelector(getLayoutIsLoading)
  const authStats = useAppSelector(getAuthStat)
  const guide = useAppSelector(getGuide)
  const [isDoLater, setIsDoLater] = useState(false)
  const [isReactionSending, setIsReactionSending] = useState(false)
  const { setScreen, eventHandler } = useAnalytic('')
  const [isPlay, setIsPlay] = useState(false)
  const [data, setData] = useState<IPaginationResponse<ICandidateModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const candidate = useMemo<ICandidateModel>(
    () => ensureArray(data.rows)[0] || {},
    [data]
  )
  const parsedTraits = useMemo(() => [...traits.characteristics, ...traits.motivations], [traits])

  const playedMapRef = useRef<Map<number, boolean>>(new Map([]))
  const handleWatchedVideo = useCallback(async (videoId: number, playing: boolean) => {
    try {
      if (!playing) {
        return
      }

      if (playedMapRef.current.get(videoId)) {
        return
      }

      playedMapRef.current.set(videoId, true)
      await CandidateApi.play(videoId)
    } catch (error) {
      console.log({ error })
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: 'NETWORK ERROR'
      })
    }
  }, [])

  useEffect(() => {
    if (location?.state?.from) {
      history.push(location?.state?.from)
    }
  }, [history, location?.state?.from])

  useEffect(() => {
    if (candidate?.video?.id) {
      handleWatchedVideo(candidate.video.id, isPlay)
    }
  }, [handleWatchedVideo, candidate?.video?.id, isPlay])

  const icons: IIcon[] = [
    {
      title: 'Nope',
      event: ETrackingEvent.BTN_VIDEO_TRASH,
      action: ECandidateReaction.NOPE,
      icon: IconNope,
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-negative-400'] }
    },
    {
      title: 'Skip',
      event: ETrackingEvent.BTN_VIDEO_SKIP,
      action: ECandidateReaction.SKIP,
      icon: IconArchive,
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-neutral-theme-300'] }
    },
    {
      title: 'Superlike',
      event: ETrackingEvent.BTN_VIDEO_SUPERLIKE,
      action: ECandidateReaction.SUPER_LIKE,
      icon: IconSuperLike,
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-orange-500'] }
    },
    {
      title: 'Like',
      event: ETrackingEvent.BTN_VIDEO_LIKE,
      action: ECandidateReaction.LIKE,
      icon: IconLikes,
      color: theme.colors['--color-cyan-500'],
      active: { color: theme.colors['--color-neutral-theme-50'], background: theme.colors['--color-cyan-400'] }
    }
  ]

  const loadVibes = useCallback(({ page = 1, limit = 20 } = {}) => {
    const promise = CandidateApi.pagination({
      page,
      limit,
      traits: parsedTraits
    })

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe(({ data }) => {
        setData(prev => ({
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: uniqWith(
            [...ensureArray(prev.rows), ...shuffle(ensureArray(data.rows))],
            (a, b) => a.id === b.id
          )
        }))
      })
  }, [dispatch, parsedTraits, unsubscribe$])

  const handleReaction = useCallback(async (action: ECandidateReaction) => {
    if (!candidate?.id) {
      return
    }

    if (authStats && authStats.countHMLikes === 9 && !isDoLater && !profile.pfv) {
      return OverlayService.setOverlay({
        open: true,
        onClose: () => setIsDoLater(true),
        content: <DialogVerifyProfile profileId={profile.id} onDoLater={() => setIsDoLater(true)}/>
      })
    }

    setIsReactionSending(true)
    const promise = CandidateApi.action(
      candidate.id,
      action,
      {
        reactionableType: EReactionTable.VIDEO,
        reactionableId: candidate.video.id
      }
    )
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => setIsReactionSending(false))
      )
      .subscribe(() => {
        setData((prev) => {
          const remainRows = ensureArray(prev.rows).slice(1)
          if (remainRows.length === 5) {
            loadVibes()
          }

          return {
            ...prev,
            rows: remainRows
          }
        })

        setIsPlay(true)
      })
  }, [candidate, authStats, isDoLater, profile, unsubscribe$, loadVibes])

  useDebounce(() => {
    setData({
      page: 1,
      pages: 1,
      total: 0,
      rows: []
    })

    loadVibes()

    if (profile.guide?.onboarding) {
      setScreen('vibes')
    }
  }, 300, [loadVibes, parsedTraits, profile?.guide?.onboarding])

  useEffect(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutPageTitle('Vibes'))
    dispatch(setLayoutPageComponent(null))
    dispatch(setLayoutShouldShowLoading(false))
    dispatch(setLayoutLoading(true))

    NavigationService.setIsAtKnowmeTalent(true)

    return () => {
      dispatch(setLayoutShouldShowLoading(true))
      NavigationService.setIsAtKnowmeTalent(false)
    }
  }, [dispatch])

  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)
  // const scale = useMemo(
  //   () => Math.min(1, elWidth / 445, elHeight / 851),
  //   [elWidth, elHeight]
  // )

  const vibeWrapperStyle = useMemo(() => {
    return {
      aspectRatio: '445 / 851',
      [(elWidth / elHeight) > (445 / 851) ? 'height' : 'width']: '100%'
    }
  }, [elWidth, elHeight])

  const bannerContent = useMemo(() => (
    <span>
      We are focusing initially on gathering qualified <span className="body2-bold">Customer Success Managers</span> (CSM) and <span className="body2-bold">Customer Support/Service</span> who have 0-3 years experience and looking for remote work.
    </span>
  ), [])

  const handleCloseBanner = useCallback(() => {
    GuideApi.edit(EUserGuide.ONBOARDING_TALENT)
    dispatch(setOnboarding({ [EUserGuide.ONBOARDING_TALENT]: true }))
    return NavigationService.setWatchedTalentOnboarding(true)
  }, [dispatch])

  useEffect(() => {
    if (!guide?.onboardingTalent && !guide?.campaignId && history.location.pathname === '/vibes') {
      BannerService.push({
        id: 'onboarding-talent',
        open: true,
        severity: EMessage.INFO,
        title: 'KNOWME\'s Talent Coverage',
        content: bannerContent,
        position: { vertical: 'top', horizontal: 'right' },
        actionText: 'Got It',
        bannerClassName: Style.bannerClassName,
        onClick: handleCloseBanner
      })
    }

    return () => {
      BannerService.reset()
    }
  }, [bannerContent, guide?.campaignId, guide?.onboardingTalent, handleCloseBanner, history.location.pathname])

  const renderVibe = () => {
    return (
      <>
        <VideoPlayer
          id="video"
          className={Style.videoPlayer}
          videoId={candidate.video?.id}
          tracks={candidate.video?.tracks}
          trackingEvent
          isPlay={isPlay}
          image={candidate.video?.urlVideoImageThumbnail}
          animatedImage={candidate.video?.urlVideoAnimatedImage}
          url={getVideoSource(candidate.video)}
          onPlayingChange={(playing) => handleWatchedVideo(candidate.video.id, playing)}
          mimeType={candidate.video?.internalSourceMetadata?.mimeType}
          style={videoPlayerSx}
        />

        <div className={clsx('relative fx fx-1 fx-ai-center fx-jc-center', Style.bottomBar)}>
          <div className={clsx('fx gap-4', Style.icons)}>
            {icons.map(({ title, event, action, icon: Icon, color, active }) => (
              <IconWrapper
                key={title}
                id={title}
                width={40}
                height={40}
                color={color}
                active={active}
                disabled={isReactionSending}
                className={Style.icon}
                onClick={eventHandler({
                  key: event,
                  contextData: { videoId: candidate.video.id }
                }, () => handleReaction(action))}
              >
                <Tooltip title={title}>
                  <Icon width={24} height={24}/>
                </Tooltip>
              </IconWrapper>
            ))}
          </div>

          {!!candidate.video.videoTranscription && (
            <Traits
              className={clsx('p-2 fx-wrap-wrap fx-ai-center', Style.traits)}
              style={{ background: '#fff' }}
              items={candidate.video.videoTranscription.traits}
            />
          )}

        </div>
      </>
    )
  }

  return (
    <div
      ref={containerRef}
      className={clsx('fx-1 fx fx-ai-center fx-jc-center', Style.vibes)}
    >
      {isLoading
        ? <EEmptyVideoVariant.Vibe/>
        : !data.rows?.length
          ? <EmptyVibes/>
          : (
            <div
              className={Style.vibesWrapper}
              style={vibeWrapperStyle}
            >
              {renderVibe()}
            </div>
          )}
    </div>
  )
}
