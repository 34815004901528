import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import ReactCardFlip, { ReactFlipCardProps } from 'react-card-flip'
import { IconArrowLeft, IconArrowRight } from 'src/icons'
import { ILinkedinEducation, ILinkedinWorkingExperience, IUserModel, IVideoModel } from 'src/interfaces'
import { getFirstName, getFullName } from 'src/utils'
import { CollapseContent } from '../collapse-content'
import { IllustrationComment, IllustrationStar } from './components/illustration'
import Style from './style.module.scss'

interface IFlipCardProps {
  children: (flipFunc: () => void) => [ReactNode, ReactNode]
  isChange?: boolean
  flipped?: boolean
}

export const FlipCard: FC<IFlipCardProps> = (props) => {
  const [isFlipped, setIsFlipped] = useState(props.flipped)
  const [cardStyles, setCardStyles] = useState<ReactFlipCardProps['cardStyles']>()

  useEffect(() => {
    if (props.flipped === undefined) return

    setIsFlipped(props.flipped)
  }, [props.flipped])

  const flipFunc = useCallback(() => {
    if (isFlipped) {
      setCardStyles({})
    } else {
      setCardStyles({
        front: {
          visibility: 'hidden'
        }
      })
    }

    setTimeout(() => {
      setIsFlipped(!isFlipped)
    }, 0)
  }, [isFlipped])

  const [Front, Back] = useMemo(() => props.children(flipFunc), [props, flipFunc])

  useEffect(() => {
    if (props.isChange && isFlipped) {
      setIsFlipped(false)
    }
  }, [isFlipped, props.isChange])

  return (
    <div>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        cardStyles={cardStyles}
      >
        {Front}
        {Back}
      </ReactCardFlip>
    </div>
  )
}

interface IProps {
  onFlip: () => void
  onDetailClick?: (e: any) => void
  educations?: ILinkedinEducation[]
  workingExperiences?: ILinkedinWorkingExperience[]
  video: IVideoModel
  author: IUserModel
  showFullName?: boolean

  /**
   * The id of the button that will be used to target the tour
   */
  detailButtonId?: string
}

export const FlipCardBack: FC<IProps> = (props) => {
  // const theme = useTheme()
  // const educations = useMemo(() => LinkedinUtil.descByDate(props.educations), [props.educations])
  // const workingExperiences = useMemo(() => LinkedinUtil.descByDate(props.workingExperiences), [props.workingExperiences])
  // const currentEducation = useMemo(() => educations[0], [educations])
  // const currentWorkingExperience = useMemo(() => workingExperiences[0], [workingExperiences])

  // const [openPersonalCharacteristics, setOpenPersonalCharacteristics] = useState(true)
  // const handleToggle = useCallback(() => {
  //   setOpenPersonalCharacteristics(!openPersonalCharacteristics)
  // }, [openPersonalCharacteristics])

  return (
    <div className={Style.FlipCardBackContainer}>
      <div className={Style.FlipCardBackContent}>
        <div className={Style.STCardSummaryHeader}>
          <IconButton className={Style.headerBackButton} onClick={() => props.onFlip()}>
            <IconArrowLeft/>
          </IconButton>

          <IllustrationStar className={Style.illustrationStar}/>
          <IllustrationComment className={Style.illustrationComment}/>
        </div>

        {/* <div className={Style.STCardSummaryGeneral}>
          <div className={Style.STCardSummaryGeneral_Section}>
            <IconBriefCase/>

            <span className="body2-medium color-neutral-theme-900">
              {currentWorkingExperience?.title && currentWorkingExperience?.company
                ? `${currentWorkingExperience?.title} at ${currentWorkingExperience?.company}`
                : 'Not Provided'}

            </span>
          </div>
          <div className={Style.STCardSummaryGeneral_Section}>
            <IconEducationHat/>
            <span className="body2-medium color-neutral-theme-900">{
              currentEducation?.school ? currentEducation?.school : 'Not Provided'
            }
            </span>
          </div>
        </div> */}

        {/* <Divider background={theme.colors['--color-neutral-theme-200']}/> */}
        <div className={Style.summaryContainer}>
          <CollapseContent open title={<span style={{ fontSize: '16px' }}>Soft Skills</span>}>
            <pre className="pl-1 mb-3 body2-regular neutral-400">{props.author?.characteristics || 'No content'}</pre>
          </CollapseContent>

          <CollapseContent open borderTop title={<span style={{ fontSize: '16px' }}>Hard Skills</span>}>
            <pre className="pl-1 mb-3 body2-regular neutral-400">{props.author?.skills || 'No content'}</pre>
          </CollapseContent>
        </div>

        <div className={clsx('fx flex-row fx-jc-space-between fx-ai-center px-3 py-2', Style.cardSummaryFooter)}>
          <div className="fx flex-col body2-bold color-neutral-theme-900">
            {props.showFullName ? getFullName(props.author) : getFirstName(props.author)}
          </div>

          <IconButton
            id={props.detailButtonId}
            className={Style.iconButtonDetail}
            onClick={props.onDetailClick}
          >
            <IconArrowRight className="svg-color-neutral-theme-900"/>
          </IconButton>
        </div>
      </div>
    </div>
  )
}
