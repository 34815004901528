import { ISortMenuOption } from 'src/components'
import { ESalaryRange, ESalaryRate } from 'src/interfaces'

export const CAMPAIGN_SALARY_RANGE_OPTIONS: (ISortMenuOption<ESalaryRange>)[] = [
  { label: 'Range', value: ESalaryRange.RANGE },
  { label: 'Custom range', value: ESalaryRange.CUSTOM },
  { label: 'Extra amount', value: ESalaryRange.EXTRA_AMOUNT }
]

export const CAMPAIGN_SALARY_RATE_OPTIONS: (ISortMenuOption<ESalaryRate>)[] = [
  { label: 'Per hour', value: ESalaryRate.PER_HOUR },
  { label: 'Per month', value: ESalaryRate.PER_MONTH },
  { label: 'Per year', value: ESalaryRate.PER_YEAR }
]

export const CAMPAIGN_SALARY_VALUE_RANGE_OPTIONS: (ISortMenuOption<string>)[] = [
  { label: '20,000 - 30,000', value: '20,000 - 30,000' },
  { label: '30,000 - 40,000', value: '30,000 - 40,000' },
  { label: '40,000 - 50,000', value: '40,000 - 50,000' },
  { label: '50,000 - 60,000', value: '50,000 - 60,000' },
  { label: '60,000 - 70,000', value: '60,000 - 70,000' }
]

export const SST_GUEST_CAMPAIGN_ID = 'guess.campaignHashId'

export const PUBLIC_CAMPAIGN_BASE_PATH = 'public-campaigns'
