import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import { IRouterOption } from 'src/router'
import { NewCampaign } from './components/detail/new'
import { ViewCampaignDetail } from './components/detail/view-detail'
import { Campaigns } from './components/list'
import { PublicSubmissions } from './components/list/public-submissions'
import { MyJobs } from './components/my-jobs'
import { PublicMyJobs } from './components/my-jobs/public-my-jobs'
import { PreviewCampaign } from './components/preview'
import { SubmissionDetail } from './components/submission-detail'
import { ECampaignRoutes } from './routes.enum'

export { ECampaignRoutes }

export const CampaignRoutes: IRouterOption[] = [
  {
    path: '/campaigns/new',
    component: NewCampaign,
    name: ECampaignRoutes.NEW_CAMPAIGN,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/campaigns',
    component: MyJobs,
    name: ECampaignRoutes.CAMPAIGN_MY_JOBS,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/campaigns/:id/submissions',
    component: Campaigns,
    name: ECampaignRoutes.CAMPAIGNS,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/campaigns/summary/preview',
    component: PreviewCampaign,
    name: ECampaignRoutes.PREVIEW_CAMPAIGN,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/campaigns/:campaignId/submissions/:submissionId',
    component: SubmissionDetail,
    name: ECampaignRoutes.CAMPAIGN_SUBMISSION_DETAIL,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/campaigns/:campaignId',
    component: ViewCampaignDetail,
    name: ECampaignRoutes.CAMPAIGN_DETAIL,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: `/${PUBLIC_CAMPAIGN_BASE_PATH}/:hashId`,
    component: PublicMyJobs,
    name: ECampaignRoutes.CAMPAIGNS_PUBLIC,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: `/${PUBLIC_CAMPAIGN_BASE_PATH}/:hashId/submissions`,
    component: PublicSubmissions,
    name: ECampaignRoutes.CAMPAIGNS_PUBLIC_SUBMISSIONS,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
