import axios from 'axios'
import { EUserGuide } from 'src/enums'
import { TAxiosResponseData } from 'src/interfaces'

export class GuideApi {
  static readonly _prefix = '/guide'

  static edit(state: EUserGuide, value?: number): TAxiosResponseData<void> {
    return axios.put(`${this._prefix}/${state}`, value !== undefined ? { value } : undefined)
  }
}
