import { Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { CreateJobButton } from 'src/modules/campaign/components/my-jobs/components/create-job-button'
import { Illustration } from './illustration'
import Style from './style.module.scss'

export const ViewJobCompleted: FC = () => {
  const theme = useTheme()

  return (
    <div className={Style.container}>
      <Illustration/>
      <div className={Style.content}>
        <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} textAlign="center">
          Ready to mingle? Let's kickstart your hiring journey.
        </Typography>
        <span className="meta-regular color-neutral-theme-900 text-center">
          Create your first job on our marketplace, and let the applicants come to you effortlessly. It's that easy!
        </span>
      </div>
      <CreateJobButton/>
    </div>
  )
}
