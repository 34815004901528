import axios from 'axios'
import { ESettingKeys } from 'src/enums'
import { TAxiosResponseData } from 'src/interfaces'

export class ManifestApi {
  static readonly _prefix = '/manifest'

  static get(): TAxiosResponseData<{ [key in ESettingKeys]?: string }> {
    return axios.get(`${this._prefix}`)
  }
}
