import { FC, useEffect, useRef, useState } from 'react'
import { useIfMobileScreen } from 'src/hooks'
import { NavigationService } from 'src/services'
import { Coin } from './animation/coin'
import { FlyingCoins } from './animation/flying-coins'
import { GiftOpen } from './animation/gift-open'
import Style from './style.module.scss'
import { STCountWrapper, STGifWrapper, STRedeemCode } from './styled'

interface IProps {
  introCredit: number
  play?: boolean
  onCompleted?: () => void
}

export const RedeemCode: FC<IProps> = ({ introCredit, play }) => {
  const isMobile = useIfMobileScreen()
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  const coinRef = useRef<any>()
  const [initialCoinPosition, setInitialCoinPosition] = useState<{ top: number; right: number}>()
  const [isFlyingCoin, setFlyingCoin] = useState(false)

  useEffect(() => {
    if (!coinRef.current) {
      return
    }

    const { x, y } = coinRef.current.getBoundingClientRect()
    setInitialCoinPosition({ right: x, top: y })
  }, [play])

  useEffect(() => {
    if (play) {
      return
    }

    setIsAnimationComplete(false)
    setFlyingCoin(false)
  }, [play])

  useEffect(() => {
    if (!isFlyingCoin) {
      return
    }

    const timeout = setTimeout(() => {
      NavigationService.setShowRedeemCode(false, 0)
      NavigationService.setFirstUploadedPfv(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
      NavigationService.setShowRedeemCode(false, 0)
      NavigationService.setFirstUploadedPfv(false)
    }
  }, [isFlyingCoin])

  return (
    <>
      {isFlyingCoin && initialCoinPosition?.top && initialCoinPosition?.right ? <FlyingCoins top={initialCoinPosition?.top} right={initialCoinPosition?.right}/> : null}
      <STRedeemCode isMobile={isMobile} ref={coinRef}>
        <div>
          <p className={Style.title}>CONGRATULATIONS!</p>
          <p className={Style.label}>You have received <span>{introCredit} credit{introCredit > 1 ? 's' : ''}</span></p>
        </div>

        <STGifWrapper>
          <STCountWrapper>
            <Coin
              onCompleted={() => {
                setFlyingCoin(true)
              }}
              play={isAnimationComplete}
            />
          </STCountWrapper>
          {play
            ? (
              <GiftOpen
                onComplete={() => {
                  setIsAnimationComplete(true)
                }}
              />
            )
            : null}
        </STGifWrapper>

      </STRedeemCode>
    </>
  )
}
