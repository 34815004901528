import axios from 'axios'
import { v4 } from 'uuid'

export class AnalyticApi {
  static _session = v4()
  static readonly _prefix = '/analytics'

  static resetSession() {
    this._session = v4()
  }

  static setSession(session: string) {
    this._session = session
  }

  static create({ data, ...payload }: {
    screen: string
    event: string
    data?: Record<string, unknown>
  }): Promise<void> {
    if (window.location.hostname.startsWith('dev-') || window.location.hostname.startsWith('demo-') || window.location.hostname.startsWith('localhost')) {
      return Promise.resolve()
    }
    return axios.post(`${this._prefix}`, {
      ...payload,
      data: data || {},
      session: this._session
    })
  }
}
