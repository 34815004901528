import axios from 'axios'
import { IPaginationQuery, IShareModel, IUserModel, TAxiosResponsePagination } from 'src/interfaces'

export class ShareApi {
  static readonly _prefix = '/share'

  static search(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IUserModel> {
    return axios.get(`${this._prefix}/emails`, { params })
  }

  static recent(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IShareModel> {
    return axios.get(`${this._prefix}/recent`, { params })
  }

  static shared(
    params: IPaginationQuery
  ): TAxiosResponsePagination<IShareModel> {
    return axios.get(`${this._prefix}/shared`, { params })
  }
}
