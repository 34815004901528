import axios from 'axios'
import { IFeedbackModel, TAxiosResponseData } from 'src/interfaces'

export class FeedbackApi {
  static readonly _prefix = '/feedback'

  static create(
    payload: {
      email?: string
      content: string
    }
  ): TAxiosResponseData<IFeedbackModel> {
    return axios.post(`${this._prefix}`, payload)
  }
}
