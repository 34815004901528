import React, { FC } from 'react'
import Lottie from 'react-lottie'
import * as earnCredit from './earn-credit.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: earnCredit,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface IEarnCreditProps {
  play?: boolean
}

export const EarnCredit: FC<IEarnCreditProps> = React.forwardRef(function EarnCredit(
  props: IEarnCreditProps,
  ref: any
) {
  return (
    <Lottie
      ref={ref}
      width={60}
      options={defaultOptions}
    />
  )
})
