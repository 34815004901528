import React, { FC } from 'react'
import Lottie from 'react-lottie'
import * as claimCreditAnimation from './claim-credit.json'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: claimCreditAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface IClaimCreditProps {
  play?: boolean
  onCompleted?: () => void
}

export const ClaimCredit: FC<IClaimCreditProps> = React.forwardRef(function ClaimCredit(
  props: IClaimCreditProps,
  ref: any
) {
  return (
    <Lottie
      ref={ref}
      width={120}
      options={defaultOptions}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => props.onCompleted?.()
        }
      ]}
    />
  )
})
